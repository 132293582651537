.component {
  color: var(--color-blue-500);
}

.componentButton {
  display: inline-flex;
  align-items: center;
  padding: var(--size-12) var(--size-24) var(--size-12) var(--size-12);
  font-size: var(--font-size-16);
  line-height: var(--line-height-base);
  color: var(--color-white);
  white-space: nowrap;
  background-color: var(--color-blue-500);

  & > .icon {
    margin-right: var(--size-6);
    width: var(--size-24);
    height: var(--size-24);
  }
}

.icon {
  display: flex;
  align-items: center;
}

.componentMenu {
  display: inline-flex;
  flex-direction: row;
}

.componentFooter {
  display: inline-flex;
  align-items: center;
  font-size: var(--font-size-14);
}
