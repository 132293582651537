.componentLg {
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.componentMd {
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-42);
  }
}

.componentSm,
.componentSmAlt {
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.componentWithSubtitleXl {
  & > .subtitleXl {
    margin-top: var(--size-12);
  }
}

.componentWithSubtitleLg {
  & > .subtitleLg {
    margin-top: var(--size-12);
  }
}

.titleXl {
  font-size: var(--font-size-62);

  @media (--viewport-md) {
    font-size: var(--font-size-112);
  }
}

.subtitleXl {
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.titleLg {
  font-size: var(--font-size-42);

  @media (--viewport-md) {
    font-size: var(--font-size-82);
  }
}

.subtitleLg {
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-62);
  }
}

.titleMd {
  font-size: var(--font-size-32);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.subtitleMd {
  font-size: var(--font-size-24);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.title,
.titleAlt,
.subtitle {
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);
}

.title,
.titleAlt {
  font-weight: var(--font-weight-semibold);
}

.title,
.subtitle {
  font-family: var(--font-family-heading);
}

.subtitle {
  font-weight: var(--font-weight-light);
}

.inheritedColor {
  color: inherit;
}
